import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";
import HtmlParser from "react-html-parser";

function Impact({data}) {
    return (
        <div className="tekrevol_dark_bg p-100">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={5} xl={5} xxl={5} className="text-center m-auto order-2 order-md-1">
                        <img
                            src={`${mediaBaseURL}${checkData(data, 'impact_image')}`}
                            className="img-fluid"
                            alt={checkData(data, 'impact_title')}/>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="offset-lg-1 my-auto order-1 order-md-2">
                        <div className="example" data-text={data?.impact_body_txt}>
                            <h2>{HtmlParser(checkData(data, 'impact_title'))}</h2>
                        </div>
                        <div>{HtmlParser(checkData(data, 'impact_description'))}</div>
                    </Col>

                </Row>
            </Container>
        </div>
    );
}

export default Impact;